<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">作业发放</span>
        <!-- 面包屑 -->
        <div class="crumbs">
          <a class="crumbs_item" @click="$router.go(-1)">作业管理 / </a>
          <span class="crumbs_item crumbs_last">{{
            $route.query.state == "2" ? "配置详情" : "作业新建"
          }}</span>
        </div>
      </div>
    </div>

    <!-- 进度 -->
    <StepBar
      class="StepBar"
      :list="['试题设置', '发放设置']"
      :tabIndex="tabIndex"
    />
    
    <a-button type="primary" v-show="$route.query.state == 1 && $route.query.courseId" @click="importLastData">导入上条配置</a-button>
    
    <!-- 试题设置 -->
    <template v-if="tabIndex == 0">
      <div class="cow-item">
        <div>
          <span class="left required">试题标题</span>
        </div>
        <a-input
          class="input"
          :disabled="!isUpdate"
          v-model="StepForm1.title"
          placeholder="请输入试题名称"
        />
      </div>
      <div class="cow-item">
        <div>
          <span class="left required">试题概况</span>
        </div>
        <a-textarea
          v-model="StepForm1.summary"
          :disabled="!isUpdate"
          placeholder="请输入试题概况"
        />
      </div>
      <div class="cow-item">
        <div>
          <span class="left required">试题详情</span>
        </div>
        <UE
          editorId="Editor1"
          :initContent="StepForm1.content"
          @input="getContent1($event, 'content')"
        />
      </div>
      <div class="cow-item">
        <div>
          <span class="left">试题附件</span>
          <a-upload
            name="videoFileId"
            :disabled="!isUpdate"
            :beforeUpload="beforeUpload"
            :showUploadList="false"
            :customRequest="customRequest"
          >
            <a-button ref="btn">
              <template v-if="uploadLoading">
                <a-icon type="loading" />
                <span>上传中（{{speed}}Mb/s）</span>
              </template>
              <template v-else>
                <a-icon type="upload" />
                <span>选择附件</span>
              </template>
            </a-button>
          </a-upload>
          <div v-if="uploadLoading">
            <a-progress :strokeWidth=3 :percent="progress" />
          </div>
        </div>
        <div class="grey" v-show="fileList.length">
          <span
            @click="openFile(item.url)"
            title="点击在线查看"
            v-for="(item, index) in fileList"
            :key="item.key"
            >{{ item.name
            }}<a-icon
              v-show="isUpdate"
              @click.stop="onFileDel(index)"
              class="icon"
              type="close-circle"
          /></span>
        </div>
      </div>
      <div class="cow-item">
        <div>
          <span class="left required">试题格式</span>
        </div>
        <a-checkbox-group
          :disabled="!isUpdate"
          v-model="paperFormat"
          style="width: 100%"
        >
          <a-checkbox :value="0">文本输入</a-checkbox>
          <a-checkbox :value="1">视频</a-checkbox>
          <a-checkbox :value="2">图片</a-checkbox>
          <a-checkbox :value="3">电子文件（如word、txt）</a-checkbox>
        </a-checkbox-group>
      </div>
      <div class="cow-item">
        <div>
          <span class="left">补充资料</span>
        </div>
        <a-checkbox-group
          :disabled="!isUpdate"
          v-model="supplyFlag"
          style="width: 100%"
        >
          <a-checkbox :value="1">有</a-checkbox>
        </a-checkbox-group>
      </div>
      
      <div class="cow-item">
        <div class="foot-btn">
          <a-button style="margin-right: 30px" @click="$router.go(-1)"
            >取消</a-button
          >
          <a-button :loading="loadingBtn" type="primary" @click="NextOne()"
            >下一步</a-button
          >
        </div>
      </div>
    </template>

    <!-- 发放设置 -->
    <template v-if="tabIndex == 1">
      <div class="cow-item">
        <h3>作答设置</h3>
        <div>
          <span class="left required">作答有效期设置</span>
        </div>
        <a-range-picker
          :disabled="!isUpdate"
          :disabled-date="disabledDate"
          class="input"
          showTime
          v-model="AnswerTimeData"
          valueFormat="YYYY-MM-DD HH:mm"
          format="YYYY-MM-DD HH:mm"
        ></a-range-picker>
        <p class="tips">
          住：若作业的有效期长期有效，可将指定时间的结束时间设置时间较长
        </p>
      </div>
      <div class="cow-item">
        <div>
          <span class="left required">作答提交说明</span>
        </div>
        <UE
          editorId="Editor2"
          :initContent="StepForm2.answerNote"
          @input="getContent2($event, 'answerNote')"
        />
      </div>
      <div class="cow-item">
        <h3>批阅设置</h3>
        <div>
          <span class="left required">批阅老师设置</span>
        </div>
        <a-select
          :disabled="!isUpdate"
          style="width: 40%"
          mode="multiple"
          v-model="teacherChecked"
          :disabled-date="disabledDate"
          :filterOption="filterInstructorList"
          placeholder="请选择批阅老师(可多选)"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option
            :value="item.userId"
            v-for="item in InstructorList"
            :key="item.name"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </div>
      <div class="cow-item">
        <div>
          <span class="left required">批阅规则说明</span>
        </div>
        <UE
          editorId="Editor3"
          :initContent="StepForm2.correctNote"
          @input="getContent2($event, 'correctNote')"
        />
      </div>
      <div class="cow-item">
        <span class="left required">批阅时间设置</span>
        <a-range-picker
          :disabled="!isUpdate"
          v-model="ReviewTimeData"
          class="input"
          showTime
          valueFormat="YYYY-MM-DD HH:mm"
          format="YYYY-MM-DD HH:mm"
        ></a-range-picker>
      </div>
      <div class="cow-item">
        <span class="left required">成绩设置</span>
        <div class="col-flex">
          <div>
            <span>总分：</span
            ><a-input-number
              v-model="StepForm2.totalScore"
              :disabled="!isUpdate"
              :min="0"
              class="number"
              placeholder="请设置总分"
            />
          </div>
          <div>
            <span>合格分数：</span
            ><a-input-number
              v-model="StepForm2.passScore"
              :disabled="!isUpdate"
              :min="0"
              class="number"
              placeholder="请设置合格分数"
            />
          </div>
          <div>
            <span>关联成绩字段：</span>
            <a-select
              placeholder="请选择关联的成绩"
              :disabled="!isUpdate"
              v-model="StepForm2.relatedScoreid"
              class="number"
            >
              <a-select-option
                v-for="item in RelatedScoreList"
                :key="item.configId"
                :value="item.configId"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </div>
        </div>
      </div>
      <div class="cow-item">
        <div class="foot-btn" v-if="isUpdate">
          <a-button
            :loading="loadingBtn"
            style="margin-right: 30px"
            @click="onSave(0)"
            >暂存</a-button
          >
          <a-button :loading="loadingBtn" type="primary" @click="onSave(1)"
            >提交</a-button
          >
        </div>
        <div class="foot-btn" v-else>
          <a-button @click="$router.go(-1)">返回</a-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import StepBar from "@/components/StepBar.vue";
import UE from "@/components/UE.vue";
import { uuid, openFile } from "@/unit/fun.js";
import moment from "moment";
export default {
  // 可用组件的哈希表
  components: { StepBar, UE },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      tabIndex: 0,
      initMobileDetail: "",
      checkGroup: [],
      supplyFlag: [],
      loadingBtn: false,
      InstructorList: [], // 批阅老师
      teacherChecked: [], // 已选的批阅老师

      RelatedScoreList: [], //关联查询

      /* 试题设置 */
      StepForm1: {
        courseId: this.$route.query.courseId, // 课程id
        courseName: this.$route.query.courseName, // 课程名称
        title: "", // 试题标题
        summary: "", // 试题概况
        content: "", // 试题详情
        attachment: [], // 试题附件
        submitFormat: "", // 试题格式
        homeworkType: 1, // 作业类型 1-主观题 2-作业   默认传1
        supplyFlag: 0 // 补充资料
      },
      fileList: [], // 试题附件
      paperFormat: [], // 试题格式
      uploadLoading: false, //附件上传load
      progress:0, // 文件上传进度
      speed:0, // 传输速度
      isUpdate: true, // 是否可更新数据

      /* 发放设置 */
      StepForm2: {
        id: 0,
        answerStartdate: "", // 作答开始时间
        answerEnddate: "", // 作答结束时间
        answerNote: "", // 作答提交说明
        homeworkTeacher: [], // 批阅老师
        correctNote: "", // 批阅规则说明
        correctStartdate: "", // 批阅开始时间
        correctEnddate: "", // 批阅结束时间
        totalScore: "", // 总分
        passScore: "", // 及格分
        relatedScoreid: undefined, // 关联成绩id
        isPublish: 0, // 配置状态  0.暂存  1.提交
        provideType: 1, //发放类型1-实时 2-定时 默认传1
        homeworkType: 1, // 作业类型 1-主观题 2-作业   默认传1
      },
      AnswerTimeData: [], // 作答时间
      ReviewTimeData: [], // 批阅时间

      importLastConfigId: null, // 上次配置id
    };
  },
  // 事件处理器
  methods: {
    moment,
    /* 试题设置 */

    // 试题附件上传前钩子
    beforeUpload(file) {
      var reg = /\.(pdf|doc|docx|txt|jpg|png|gif|jpeg|mp4|xlsx|xls)(\?.*)?$/;
      if (!reg.test(file.name)) {
        this.$message.warning(
          this.$regular.fileReg.msg +
            "，仅支持：pdf、doc、docx、txt、jpg、png、gif、jpeg、mp4、xlsx、xls"
        );
        return false;
      }

      // const isLt2M = (file.size / 1024 / 1024) < 100;
      // if (!isLt2M) {
      //   this.$message.error("附件内存大小需在100MB之内");
      //   return false;
      // }

      if (this.uploadLoading) {
        this.$message.warning("附件正在上传中，请稍后重试");
        return false;
      }
    },

    // 附件上传 - 单文件
    customRequest(fileData) {
      this.progress = 0
      this.speed = 0
      this.uploadLoading = true

      const bucket = 'user/homework/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.fileList.push({
            name: fileName,
            url: url,
            key: uuid(),
            type: this.returnType(url)
          })
          this.$message.success("上传成功");
          setTimeout(() => {
            this.uploadLoading = false
          }, 400);
        } else {
          this.$message.error("上传失败");
          this.uploadLoading = false
        }
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
      this.progress = progress
      this.speed = speed
    },

    // 附件类型返回
    returnType(url) {
      let lastIndex = url.lastIndexOf(".");
      let suffix = url.substring(lastIndex + 1);

      var officeFiles = ["pdf", "doc", "docx", "xlsx", "xls"]; // 办公文件
      var imgFiles = ["jpg", "png", "gif", "jpeg"]; // 图片文件
      var txtFiles = ["txt"]; // 文本文件
      var videoFiles = ["mp4"]; // 视频文件

      var type = "";
      if (officeFiles.indexOf(suffix) != -1) {
        type = 1;
      } else if (imgFiles.indexOf(suffix) != -1) {
        type = 2;
      } else if (txtFiles.indexOf(suffix) != -1) {
        type = 3;
      } else if (videoFiles.indexOf(suffix) != -1) {
        type = 4;
      }
      return type;
    },

    // 已上传附件删除
    onFileDel(index) {
      this.fileList.splice(index, 1);
    },

    // 查询试题详情
    getPaperDetail() {
      this.loadingBtn = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/homeworkLibrary/getHomeWorkLibrary",
        params: {
          configId: this.$route.query.configId,
        },
      }).then((res) => {
        this.loadingBtn = false;
        if (res.code == 200 && res.success) {
          this.StepForm1.id = res.data.id; // 课程id
          this.StepForm1.courseId = res.data.courseId; // 课程id
          this.StepForm1.courseName = res.data.courseName; // 课程名称
          this.StepForm1.title = res.data.title; // 试题标题
          this.StepForm1.summary = res.data.summary; // 试题概况
          this.StepForm1.content = res.data.content; // 试题详情
          this.paperFormat = res.data.submitFormatList; // 试题格式
          this.supplyFlag = [res.data.supplyFlag]; // 是否有补充资料

          if (res.data.attachmentVOList) {
            res.data.attachmentVOList.forEach((element) => {
              element.key = uuid();
            });
          }

          this.isUpdate = res.data.isUpdate == 1 ? true : false; // 是否可更新数据

          this.fileList = res.data.attachmentVOList
            ? res.data.attachmentVOList
            : []; // 试题附件
        } else {
        }
      });
    },

    // 试题提交并进入下一步
    NextOne() {
      if (!this.StepForm1.title) {
        this.$message.error("请输入试题名称");
        return;
      }
      if (!this.StepForm1.summary) {
        this.$message.error("请输入试题概况");
        return;
      }
      if (!this.StepForm1.content) {
        this.$message.error("请输入试题详情");
        return;
      }
      if (!this.paperFormat.length) {
        this.$message.error("请至少选择一项提交试题的格式");
        return;
      }

      // 处理提交试题的格式
      let submitFormat = [];
      for (var i = 0; i < 4; i++) {
        if (this.paperFormat.indexOf(i) != -1) {
          submitFormat.push(1);
        } else {
          submitFormat.push(0);
        }
      }
      this.StepForm1.submitFormat = submitFormat.toString();

      if (this.fileList.length) {
        this.StepForm1.attachment = JSON.stringify(this.fileList);
      } else {
        this.StepForm1.attachment = "";
      }

      // 存储补充资料字段
      this.StepForm1.supplyFlag = this.supplyFlag.length?1:0;

      this.loadingBtn = true;
      // 可以更新时，state 为1走新建，2.更新
      if (this.isUpdate) {
        var state = this.$route.query.state;
        var url =
          state == 1
            ? "/hxclass-management/homeworkLibrary/saveHomeworkLibrary"
            : "/hxclass-management/homeworkLibrary/updateHomeWorkLibrary";
        this.$ajax({
          method: "post",
          url: url,
          params: this.StepForm1,
        }).then((res) => {
          this.loadingBtn = false;
          if (res.code == 200 && res.success) {
            this.StepForm2.id = res.data;

            // 如果操作导入上次数据 传上次配置id 否则正常
            this.getConfigDetail(this.importLastFlag?this.importLastConfigId:res.data);
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        // 不可编辑，直接下一步
        this.StepForm2.id = this.$route.query.configId;
        this.getConfigDetail(this.$route.query.configId);
      }
      this.getTeacherList();
      this.getRelatedScoreList();
    },

    // 查询配置详情
    getConfigDetail(id) {
      this.loadingBtn = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/homeworkConfig/getConfigInfo",
        params: {
          configId: id,
        },
      }).then((res) => {
        this.loadingBtn = false;
        if (res.code == 200 && res.success) {
          this.tabIndex = 1;

          // 如果操作导入上次数据，不覆盖这两个时间字段
          if(!this.importLastFlag) {
            this.AnswerTimeData = [
              res.data.answerStartdate,
              res.data.answerEnddate,
            ];
            this.ReviewTimeData = [
              res.data.correctStartdate,
              res.data.correctEnddate,
            ];
          }
          
          this.StepForm2.answerNote = res.data.answerNote;
          this.StepForm2.correctNote = res.data.correctNote;
          
          this.StepForm2.totalScore = res.data.totalScore;
          this.StepForm2.passScore = res.data.passScore;
          this.StepForm2.relatedScoreid = res.data.relatedScoreid
            ? Number(res.data.relatedScoreid)
            : undefined;

          this.teacherChecked = [];
          res.data.homeworkTeacher.forEach((element) => {
            this.teacherChecked.push(element.teacherId);
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 查询批阅老师数据
    getTeacherList() {
      this.$ajax({
        method: "get",
        url: "/hxclass-management/homeworkConfig/getTeacherList",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.InstructorList = res.data;
        }
      });
    },

    // 查询关联成绩
    getRelatedScoreList() {
      this.$ajax({
        method: "get",
        url: "/hxclass-management/homeworkConfig/getRelatedScoreId",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.RelatedScoreList = res.data;
        }
      });
    },

    disabledDate(current) {
      return current && current < moment().subtract(1, "days").endOf("day");
    },

    // 打开文件
    openFile(url) {
      openFile(url);
    },

    getContent1(e, name) {
      this.StepForm1[name] = e;
    },

    getContent2(e, name) {
      this.StepForm2[name] = e;
    },

    // 改写批阅老师搜索条件
    filterInstructorList(inputValue, option) {
      return option.key.indexOf(inputValue) != -1;
    },

    // 配置提交或暂存
    onSave(type) {
      if (type == 1) {
        if (!this.AnswerTimeData.length) {
          this.$message.error("请设置作答有效期");
          return;
        }

        if (!this.StepForm2.answerNote) {
          this.$message.error("请输入作答提交说明");
          return;
        }
        if (!this.teacherChecked.length) {
          this.$message.error("请至少选择一名批阅老师");
          return;
        }

        if (!this.StepForm2.correctNote) {
          this.$message.error("请输入批阅规则说明");
          return;
        }
        if (!this.ReviewTimeData.length) {
          this.$message.error("请设置批阅时间");
          return;
        }

        if (!this.StepForm2.totalScore) {
          this.$message.error("请设置试题总分");
          return;
        }
        if (!this.StepForm2.passScore) {
          this.$message.error("请设置试题及格分");
          return;
        }
        if (!this.StepForm2.relatedScoreid) {
          this.$message.error("请选择关联的成绩");
          return;
        }
      }
      if (this.StepForm2.passScore > this.StepForm2.totalScore) {
        this.$message.error("及格分不得大于总分");
        return;
      }

      /* 处理批阅老师数组 */
      this.StepForm2.homeworkTeacher = [];
      this.teacherChecked.forEach((childItem) => {
        this.InstructorList.forEach((uItem) => {
          if (childItem == uItem.userId) {
            this.StepForm2.homeworkTeacher.push({
              teacherId: childItem,
              teacherName: uItem.name,
            });
          }
        });
      });

      /* 作答有效期设置 */
      if (this.AnswerTimeData.length) {
        this.StepForm2.answerStartdate = this.AnswerTimeData[0];
        this.StepForm2.answerEnddate = this.AnswerTimeData[1];
      } else {
        this.StepForm2.answerStartdate = null;
        this.StepForm2.answerEnddate = null;
      }

      /* 批阅有效期设置 */
      if (this.ReviewTimeData.length) {
        this.StepForm2.correctStartdate = this.ReviewTimeData[0];
        this.StepForm2.correctEnddate = this.ReviewTimeData[1];
      } else {
        this.StepForm2.correctStartdate = null;
        this.StepForm2.correctEnddate = null;
      }

      this.StepForm2.isPublish = type; // 配置状态 type 0.暂存  1.提交

      this.loadingBtn = true;
      this.$ajax({
        method: "post",
        url: "/hxclass-management/homeworkConfig/updateHomeworkConfig",
        params: this.StepForm2,
      }).then((res) => {
        this.loadingBtn = false;
        if (res.code == 200 && res.success) {
          this.$message.success(type ? "提交" : "暂存" + "成功");
          this.$router.go(-1);
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 导入上次数据
    importLastData() {
      if(!this.$route.query.courseId) return
      this.importLastFlag = true; // 操作标记
      this.$ajax({
        url: '/hxclass-management/homeworkLibrary/importLastData',
        method: 'get',
        params: {
          courseId: this.$route.query.courseId
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.importLastConfigId = res.data.id;
          this.StepForm1.courseId = res.data.courseId; // 课程id
          this.StepForm1.courseName = res.data.courseName; // 课程名称
          this.StepForm1.title = res.data.title; // 试题标题
          this.StepForm1.summary = res.data.summary; // 试题概况
          this.StepForm1.content = res.data.content; // 试题详情
          this.paperFormat = res.data.submitFormatList; // 试题格式
          this.supplyFlag = [res.data.supplyFlag]; // 是否有补充资料

          if (res.data.attachmentVOList) {
            res.data.attachmentVOList.forEach((element) => {
              element.key = uuid();
            });
          }

          this.fileList = res.data.attachmentVOList
            ? res.data.attachmentVOList
            : []; // 试题附件
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {
    // state 1.新建  2.详情
    if (this.$route.query.state == "2") {
      this.getPaperDetail();
    }
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.StepBar {
  width: 500px;
  padding: 22px;
  margin: 22px auto;
}
.cow-item {
  margin-top: 20px;
  margin-left: 10%;
  margin-right: 10%;
  h3 {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 17px;
  }
  .tips {
    font-size: 12px;
    font-size: #999999;
    margin-top: 5px;
  }
  .left {
    text-align: right;
    height: 32px;
    line-height: 32px;
    &.required::before {
      content: "*";
      color: red;
      margin-right: 2px;
    }
    &::after {
      content: "：";
    }
  }
  .input {
    width: 30%;
  }
  .grey {
    margin-top: 20px;
    background: #f9f9f9;
    min-height: 100px;
    padding: 22px;
    border-radius: 3px;
    span {
      display: inline-block;
      font-size: 14px;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 10px 20px;
      background: #ffffff;
      border-radius: 4px;
      cursor: pointer;
      .icon {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
  .col-flex {
    display: flex;
    > div {
      display: flex;
      min-width: 30%;
      align-items: center;
      margin-right: 20px;
      span {
        white-space: nowrap;
      }
      .number {
        width: 85%;
      }
    }
    > div:last-child {
      margin-right: 0;
    }
  }
}
.foot-btn {
  border-top: 1px solid #e3e3e3;
  padding-top: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: right;
}
</style>
