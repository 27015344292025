<template>
  <div class="StepBar">
    <template v-for="(item,i) in list">
      <div class="item" :class="i<=tabIndex?'color':''" :key="'item'+i">
        <div class="index">{{i+1}}</div>
        <span>{{item}}</span>
      </div>
      <div class="icon" :class="i<=tabIndex?'color':''" v-if="(i+1) < list.length" :key="'icon'+i">
        <div></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    list: {
      type: Array,
      default: ()=>[]
    },
    tabIndex: {
      type: Number,
      default: 0
    }
  },
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
@grey: #C4C4C4;
.flexCenter{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.StepBar:extend(.flexCenter){
  font-size: 16px;
  color: #333;
  font-weight: 500;
  padding-bottom: 30px;
  .item,.icon{
    &:extend(.flexCenter);
    flex: 1;
    height: 45px;
    position: relative;
  }
  .item{
    &.color{
      .index{
        color: @theme;
        border-color: @theme;
      }
      span{
        color: #333;
      }
    }
    .index:extend(.flexCenter){
      width: 45px;
      height: 45px;
      border: 3px solid @grey;
      box-sizing: border-box;
      border-radius: 50%;
      font-size: 18px;
      font-weight: 500;
      color: @grey;
    }
    span{
      position: absolute;
      top: 53px;
      line-height: 22px;
      color: @grey;
    }
  }
  .icon{
    &.color{
      >div{
        background-color: @theme;
        &::after{
          border-left-color: @theme;
        }
      }
    }
    >div{
      height: 3px;
      width: 70%;
      background-color: @grey;
      &::after{
        content: ' ';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
        right: 0;
        border: 8px solid;
        border-left-width: 16px;
        border-color: transparent transparent transparent @grey;
      }
    }
  }
}
</style>
